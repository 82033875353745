<!-- @format -->

<template>
  <div class="set_item">
    <div class="title">{{ detial.name }}</div>
    <div class="list">
      <div class="item">
        <div class="switch_btn">
          <div class="label">
            <span class="txt">标题</span>
          </div>
          <el-switch v-model="detial.showLabel"></el-switch>
        </div>
        <div class="sub_list" v-show="detial.showLabel">
          <div class="sub_item">
            <div class="sub_label">文字内容</div>
            <div class="sub_cont">
              <el-input
                v-model="detial.label"
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>
          <div class="sub_item">
            <div class="sub_label">文字样式</div>
            <div class="sub_cont text_style">
              <el-select
                v-model="detial.labelStyle.fontSize"
                placeholder="请选择"
              >
                <template v-for="i in 100">
                  <el-option
                    v-if="i > 11"
                    :label="i"
                    :value="i"
                    :key="i"
                  ></el-option>
                </template>
              </el-select>
              <el-color-picker
                v-model="detial.labelStyle.color"
                size="mini"
                show-alpha
              ></el-color-picker>
              <div
                class="iconfont icon-zitijiacu"
                :class="{ active: detial.labelStyle.fontWight == 'bold' }"
                @click="
                  detial.labelStyle.fontWight =
                    detial.labelStyle.fontWight == 'bold' ? 'normal' : 'bold'
                "
              ></div>
              <div
                class="iconfont icon-T-qingxie"
                :class="{ active: detial.labelStyle.fontStyle == 'italic' }"
                @click="
                  detial.labelStyle.fontStyle =
                    detial.labelStyle.fontStyle == 'italic'
                      ? 'normal'
                      : 'italic'
                "
              ></div>
              <div
                class="iconfont icon-xiahuaxian"
                :class="{
                  active: detial.labelStyle.textDecoration == 'underline',
                }"
                @click="
                  detial.labelStyle.textDecoration =
                    detial.labelStyle.textDecoration == 'underline'
                      ? 'none'
                      : 'underline'
                "
              ></div>
            </div>
          </div>
          <div class="sub_item">
            <div class="sub_label">对齐方式</div>
            <div class="sub_cont">
              <el-radio-group v-model="detial.labelStyle.textAlign" size="mini">
                <el-radio-button label="居左"></el-radio-button>
                <el-radio-button label="居中"></el-radio-button>
                <el-radio-button label="居右"></el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <div class="sub_item">
            <div class="sub_label">宽度</div>
            <div class="sub_cont silder">
              <el-slider
                v-model="detial.labelStyle.width"
                :max="500"
                :step="10"
              ></el-slider>
            </div>
          </div>
        </div>
      </div>
      <!-- 选项 -->
      <div class="option">
        <div class="head">
          <span class="txt">选项</span>
        </div>
        <div class="option-list">
          <draggable
            v-model="detial.options"
            class="option-wrap"
            animation="300"
            group="options"
            handle=".sort-handle"
          >
            <transition-group class="drag-list">
              <div
                class="opt-item"
                v-for="(item, index) in detial.options"
                :key="index"
              >
                <div class="sort-handle el-icon-s-operation"></div>
                <el-input
                  v-model="item.value"
                  placeholder="请输入内容"
                ></el-input>
                <div
                  class="el-icon-remove-outline"
                  v-if="detial.options.length > 1"
                  @click="detial.options.splice(index, 1)"
                ></div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <div class="add-btn">
          <el-button type="primary" size="mini" @click="addOptionItem"
            >添加选项</el-button
          >
        </div>
      </div>
      <div class="item">
        <div class="switch_btn">
          <div class="label">
            <span class="txt">输入框</span>
          </div>
          <div
            class="fold_btn"
            :class="{ active: inputFoldFlag }"
            @click="inputFoldFlag = !inputFoldFlag"
          >
            <span>{{ inputFoldFlag ? "收起" : "展开" }}</span>
            <i class="iconfont icon-zhankai"></i>
          </div>
        </div>
        <transition name="fade">
          <div class="sub_list" v-show="inputFoldFlag">
            <div class="sub_item">
              <div class="sub_label">占位提示</div>
              <div class="sub_cont">
                <el-input
                  v-model="detial.placeholder"
                  placeholder="请输入内容"
                ></el-input>
              </div>
            </div>
            <div class="sub_item">
              <div class="sub_label">高度</div>
              <div class="sub_cont silder">
                <el-slider
                  v-model="detial.inputStyle.height"
                  :max="200"
                  :min="20"
                ></el-slider>
              </div>
            </div>
            <div class="sub_item">
              <div class="sub_label">边框</div>
              <div class="sub_cont border_style">
                <el-input
                  v-model="detial.inputStyle.borderWidth"
                  type="number"
                  min="0"
                  placeholder="请输入内容"
                ></el-input>
                <el-select
                  v-model="detial.inputStyle.borderStyle"
                  placeholder="请选择"
                >
                  <el-option label="实线" value="solid"></el-option>
                  <el-option label="虚线" value="dashed"></el-option>
                  <el-option label="点线" value="dotted"></el-option>
                </el-select>
                <el-color-picker
                  v-model="detial.inputStyle.borderColor"
                  size="mini"
                  show-alpha
                ></el-color-picker>
              </div>
            </div>
            <div class="sub_item">
              <div class="sub_label">圆角</div>
              <div class="sub_cont silder">
                <el-slider
                  v-model="detial.inputStyle.borderRadius"
                  :max="200"
                ></el-slider>
              </div>
            </div>
            <div class="sub_item">
              <div class="sub_label">背景</div>
              <div class="sub_cont">
                <el-color-picker
                  v-model="detial.inputStyle.backgroundColor"
                  size="mini"
                  show-alpha
                ></el-color-picker>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="item">
        <div class="switch_btn">
          <div class="label">
            <span class="txt">表单项</span>
          </div>
          <div
            class="fold_btn"
            :class="{ active: itemFoldFlag }"
            @click="itemFoldFlag = !itemFoldFlag"
          >
            <span>{{ itemFoldFlag ? "收起" : "展开" }}</span>
            <i class="iconfont icon-zhankai"></i>
          </div>
        </div>
        <transition name="fade">
          <div class="sub_list" v-show="itemFoldFlag">
            <div class="sub_item">
              <div class="sub_label">边框</div>
              <div class="sub_cont border_style">
                <el-input
                  v-model="detial.itemStyle.borderWidth"
                  type="number"
                  min="0"
                  placeholder="请输入内容"
                ></el-input>
                <el-select
                  v-model="detial.itemStyle.borderStyle"
                  placeholder="请选择"
                >
                  <el-option label="实线" value="solid"></el-option>
                  <el-option label="虚线" value="dashed"></el-option>
                  <el-option label="点线" value="dotted"></el-option>
                </el-select>
                <el-color-picker
                  v-model="detial.itemStyle.borderColor"
                  size="mini"
                  show-alpha
                ></el-color-picker>
              </div>
            </div>
            <div class="sub_item">
              <div class="sub_label">圆角</div>
              <div class="sub_cont silder">
                <el-slider
                  v-model="detial.itemStyle.borderRadius"
                  :max="200"
                ></el-slider>
              </div>
            </div>
            <div class="sub_item">
              <div class="sub_label">背景</div>
              <div class="sub_cont">
                <el-color-picker
                  v-model="detial.itemStyle.backgroundColor"
                  size="mini"
                  show-alpha
                ></el-color-picker>
              </div>
            </div>
            <div class="sub_item">
              <div class="sub_label">内边距</div>
              <div class="sub_cont">
                <el-radio-group v-model="itemPaddingFlag" size="mini">
                  <el-radio-button label="上下"></el-radio-button>
                  <el-radio-button label="左右"></el-radio-button>
                </el-radio-group>
              </div>
            </div>
            <div class="sub_item" v-show="itemPaddingFlag == '上下'">
              <div class="sub_label">上下</div>
              <div class="sub_cont silder">
                <el-slider
                  v-model="detial.itemStyle.paddingVertical"
                ></el-slider>
              </div>
            </div>
            <div class="sub_item" v-show="itemPaddingFlag == '左右'">
              <div class="sub_label">左右</div>
              <div class="sub_cont silder">
                <el-slider
                  v-model="detial.itemStyle.paddingHorizontal"
                ></el-slider>
              </div>
            </div>
            <div class="sub_item">
              <div class="sub_label">间隔</div>
              <div class="sub_cont silder">
                <el-slider v-model="detial.itemStyle.interval"></el-slider>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="item">
        <div class="switch_btn">
          <div class="label">
            <span class="txt">必填</span>
          </div>
          <div class="f_cont">
            <el-switch v-model="detial.required"></el-switch>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="switch_btn">
          <div class="label">
            <span class="txt">统计查询条件</span>
          </div>
          <div class="f_cont">
            <el-switch v-model="detial.queryItemVal"></el-switch>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="switch_btn">
          <div class="label">
            <span class="txt">统计展示列</span>
          </div>
          <div class="f_cont">
            <el-switch v-model="detial.columnItemVal"></el-switch>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  props: {
    detial: {
      type: Object,
    },
  },
  data() {
    return {
      inputFoldFlag: false,
      itemFoldFlag: false,
      itemPaddingFlag: "上下",
      optionI: 1,
    };
  },
  methods: {
    addOptionItem() {
      if (this.detial.options.some((o) => o.value == "选项" + this.optionI)) {
        this.optionI++;
        this.addOptionItem();
      } else {
        const item = {
          value: "选项" + this.optionI,
        };
        this.detial.options.push(item);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.option {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  .head {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 10px 0;
    position: relative;
    .txt {
      padding: 0 30px;
      background-color: #fff;
      position: relative;
      z-index: 9;
    }
    &::after {
      content: "";
      display: block;
      width: 80%;
      height: 1px;
      background-color: #ddd;
      position: absolute;
      top: 50%;
      left: 10%;
    }
  }
  .option-list {
    padding: 10px 0;
    margin: 0 5px;
    background-color: #f5f5f5;
    .drag-list {
      display: block;
      max-height: 300px;
      overflow-y: auto;
      .opt-item {
        display: flex;
        align-items: center;
        padding: 0 10px;
        margin: 0 0 10px;
        .sort-handle {
          font-size: 20px;
          color: #999;
          cursor: move;
        }
        .el-input {
          flex: 1;
          margin: 0 10px;
          ::v-deep .el-input__inner {
            height: 30px;
            padding: 0 10px;
          }
        }
        .el-icon-remove-outline {
          font-size: 20px;
          color: #f00;
          cursor: pointer;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .add-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0;
    .el-button {
      width: 225px;
    }
  }
}
</style>

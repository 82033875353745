<template>
    <div class="set_item">
        <div class="title">{{detial.name}}</div>
        <div class="list">
            <div class="item">
                <div class="switch_btn">
                    <div class="label">
                        <span class="txt">高度</span>
                    </div>
                    <div class="f_cont silder">
                        <el-slider v-model="detial.size" :min="1"></el-slider>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="switch_btn">
                    <div class="label">
                        <span class="txt">颜色</span>
                    </div>
                    <div class="f_cont">
                        <el-color-picker v-model="detial.dividerColor" size="mini" show-alpha></el-color-picker>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="switch_btn">
                    <div class="label">
                        <span class="txt">间隔</span>
                    </div>
                    <div class="f_cont">
                        <el-radio-group v-model="dividerMarginFlag" size="mini">
                            <el-radio-button label="上下"></el-radio-button>
                            <el-radio-button label="左右"></el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="item" v-show="dividerMarginFlag == '上下'">
                <div class="switch_btn">
                    <div class="label">
                        <span class="txt">上下</span>
                    </div>
                    <div class="f_cont silder">
                        <el-slider v-model="detial.marginVertical" :max="200"></el-slider>
                    </div>
                </div>
            </div>
            <div class="item" v-show="dividerMarginFlag == '左右'">
                <div class="switch_btn">
                    <div class="label">
                        <span class="txt">左右</span>
                    </div>
                    <div class="f_cont silder">
                        <el-slider v-model="detial.marginHorizontal" :max="500"></el-slider>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="switch_btn">
                    <div class="label">
                        <span class="txt">显示文案</span>
                    </div>
                    <div class="f_cont">
                        <el-switch v-model="detial.showText"></el-switch>
                    </div>
                </div>
                <transition name="fade">
                    <div class="sub_list" v-show="detial.showText">
                        <div class="sub_item">
                            <div class="sub_label">文案内容</div>
                            <div class="sub_cont">
                                <el-input v-model="detial.dividerText" placeholder="请输入内容"></el-input>
                            </div>
                        </div>
                        <div class="sub_item">
                            <div class="sub_label">文字样式</div>
                            <div class="sub_cont text_style">
                                <el-select v-model="detial.fontSize" placeholder="请选择">
                                    <template v-for="i in 100">
                                        <el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
                                    </template>
                                </el-select>
                                <el-color-picker v-model="detial.color" size="mini" show-alpha></el-color-picker>
                                <div class="iconfont icon-zitijiacu" :class="{active: detial.fontWight == 'bold'}" @click="detial.fontWight = detial.fontWight == 'bold'?'normal':'bold'"></div>
                                <div class="iconfont icon-T-qingxie" :class="{active: detial.fontStyle == 'italic'}" @click="detial.fontStyle = detial.fontStyle == 'italic'?'normal':'italic'"></div>
                                <div class="iconfont icon-xiahuaxian" :class="{active: detial.textDecoration == 'underline'}" @click="detial.textDecoration = detial.textDecoration == 'underline'?'none':'underline'"></div>
                            </div>
                        </div>
                        <div class="sub_item">
                            <div class="sub_label">文案位置</div>
                            <div class="sub_cont">
                                <el-radio-group v-model="detial.dividerTextPosition" size="mini">
                                    <el-radio-button label="居左"></el-radio-button>
                                    <el-radio-button label="居中"></el-radio-button>
                                    <el-radio-button label="居右"></el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <div class="item">
                <div class="switch_btn">
                    <div class="label">
                        <span class="txt">表单项</span>
                    </div>
                    <div class="fold_btn" :class="{active: itemFoldFlag}" @click="itemFoldFlag = !itemFoldFlag">
                        <span>{{itemFoldFlag?'收起':'展开'}}</span>
                        <i class="iconfont icon-zhankai"></i>
                    </div>
                </div>
                <transition name="fade">
                    <div class="sub_list" v-show="itemFoldFlag">
                        <div class="sub_item">
                            <div class="sub_label">边框</div>
                            <div class="sub_cont border_style">
                                <el-input v-model="detial.itemStyle.borderWidth" type="number" min="0" placeholder="请输入内容"></el-input>
                                <el-select v-model="detial.itemStyle.borderStyle" placeholder="请选择">
                                    <el-option label="实线" value="solid"></el-option>
                                    <el-option label="虚线" value="dashed"></el-option>
                                    <el-option label="点线" value="dotted"></el-option>
                                </el-select>
                                <el-color-picker v-model="detial.itemStyle.borderColor" size="mini" show-alpha></el-color-picker>
                            </div>
                        </div>
                        <div class="sub_item">
                            <div class="sub_label">圆角</div>
                            <div class="sub_cont silder">
                                <el-slider v-model="detial.itemStyle.borderRadius" :max="200"></el-slider>
                            </div>
                        </div>
                        <div class="sub_item">
                            <div class="sub_label">背景</div>
                            <div class="sub_cont">
                                <el-color-picker v-model="detial.itemStyle.backgroundColor" size="mini" show-alpha></el-color-picker>
                            </div>
                        </div>
                        <div class="sub_item">
                            <div class="sub_label">内边距</div>
                            <div class="sub_cont">
                                <el-radio-group v-model="itemPaddingFlag" size="mini">
                                    <el-radio-button label="上下"></el-radio-button>
                                    <el-radio-button label="左右"></el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="sub_item" v-show="itemPaddingFlag == '上下'">
                            <div class="sub_label">上下</div>
                            <div class="sub_cont silder">
                                <el-slider v-model="detial.itemStyle.paddingVertical"></el-slider>
                            </div>
                        </div>
                        <div class="sub_item" v-show="itemPaddingFlag == '左右'">
                            <div class="sub_label">左右</div>
                            <div class="sub_cont silder">
                                <el-slider v-model="detial.itemStyle.paddingHorizontal"></el-slider>
                            </div>
                        </div>
                        <div class="sub_item">
                            <div class="sub_label">间隔</div>
                            <div class="sub_cont silder">
                                <el-slider v-model="detial.itemStyle.interval"></el-slider>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        detial: {
            type: Object,
        },
    },
    data() {
        return {
            itemFoldFlag: false,
            itemPaddingFlag: "上下",
            dividerMarginFlag: "上下",
        };
    },
};
</script>